<template>
  <b-card
      class="warehouse-edit-wrapper"
  >




    <b-form id="warehouseForm" class="edit-form mt-2">
      <b-row>
        <b-col md="12">
          <div class="inner-card-title">基本信息</div>
        </b-col>

        <!--仓库card-->
        <b-col md="4">
          <b-card header="仓库">
            <!--仓库方-->
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="仓库方"
                  label-for="contact"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="company_id"
                    size="sm"
                    v-model="company_name"
                    v-b-modal.modal-select-company
                    placeholder="点击选择仓库方"
                    readonly
                />
              </b-form-group>
            </b-col>
            <!--名称-->
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="名称"
                  label-for="warehouse_name"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="warehouse_name"
                    size="sm"
                    v-model="warehouse.warehouse_name"
                />
              </b-form-group>
            </b-col>
            <!--售卖类型-->
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="售卖类型"
                  label-for="sales_type"
                  label-size="sm"
                  class="mb-1"
              >

                <v-select
                    id="sales_type"
                    :options="getCodeOptions('store_sales_type')"
                    :clearable="true"
                    v-model="warehouse.sales_type"
                    class="select-size-sm"
                    placeholder="请选择售卖类型"
                    :reduce="option => option.value"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <!--ERP编码-->
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="ERP编码"
                  label-for="erp_code"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="erp_code"
                    size="sm"
                    v-model="warehouse.erp_code"
                />
              </b-form-group>
            </b-col>
            <!--中台仓库类型-->
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="中台仓库类型"
                  label-for="zt_warehouse_type"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="zt_warehouse_type"
                    size="sm"
                    readonly
                    v-model="zt_warehouse_type"
                />
              </b-form-group>
            </b-col>
            <!--是否虚拟仓-->
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="是否虚拟仓"
                  label-for="is_virtual"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-checkbox
                    name="check-button"
                    switch
                    inline
                    id="is_virtual"
                    v-model="warehouse.is_virtual"
                    value="1"
                    unchecked-value="0"
                ></b-form-checkbox>
              </b-form-group>
            </b-col>

            <!--是否费用仓-->
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="是否费用仓"
                  label-for="is_expense"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-checkbox
                    name="check-button"
                    switch
                    inline
                    id="is_expense"
                    v-model="warehouse.is_expense"
                    value="1"
                    unchecked-value="0"
                ></b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-card>
        </b-col>

        <!--地址card-->
        <b-col md="4">
          <b-card header="地址">
            <!--位置-->
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="位置"
                  label-for="location"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="location"
                    size="sm"
                    v-model="warehouse.location"
                />
              </b-form-group>
            </b-col>
            <!--省市区-->
            <b-col md="12">
              <area-select v-if="warehouse.loaded"
                           :provinceId="province_id"
                           :cityId="city_id"
                           :areaId="area_id"
                           ref="areaSel"
                           :level="level"
                           :isRequired=true
              />
            </b-col>
          </b-card>
        </b-col>

        <!--联系人card-->
        <b-col md="4">
          <b-card header="联系人">
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="联系人"
                  label-for="contact"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="user_id"
                    size="sm"
                    v-model="contact"
                    v-b-modal.modal-select-user
                    placeholder="点击选择联系人"
                    readonly=""
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="联系电话"
                  label-for="mobile"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="mobile"
                    size="sm"
                    v-model="warehouse.mobile"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="紧急联系人"
                  label-for="contact_other"
                  label-size="sm"
                  class="mb-1 "
              >
                <b-form-input
                    id="contact_other"
                    size="sm"
                    v-model="warehouse.contact_other"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="紧急联系人电话"
                  label-for="mobile_other"
                  label-size="sm"
                  class="mb-1 "
              >
                <b-form-input
                    id="mobile_other"
                    size="sm"
                    v-model="warehouse.mobile_other"
                />
              </b-form-group>
            </b-col>
          </b-card>
        </b-col>

        <!--店铺card-->
        <b-col md="4">
          <b-card header="店铺">
            <!-- 选择仓库 -->
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="店铺"
                  label-for="applier_name"
                  label-size="sm"
                  class="mb-1"
              >
                <div
                    v-for="(p, index) in storeArr"
                    :key="index">
                  <b-form-input
                      id="applier_name"
                      size="sm"
                      v-b-modal.modal-select-stores
                      placeholder="点击搜索店铺"

                      v-model="storeArr[index].name"
                      :clearable="true"
                      style="width: 85%; float: left"
                  >
                  </b-form-input>
                  <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="flat-secondary"
                      style="margin-left: 5px"
                      @click="removeStore(index)" size="sm">
                    删除
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-card>
        </b-col>

        <!--其他card-->
        <b-col md="4">
          <b-card header="其他">
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="打印等分"
                  label-for="label"
                  label-size="sm"
                  class="mb-1"
              >
                <v-select
                    id="print_page"
                    :options="getCodeOptions('print_page')"
                    :clearable="true"
                    v-model="printPageType"
                    @input="changeSelect('print_pagetype',$event)"
                    class="select-size-sm"
                    placeholder="请选择打印等分"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="备注"
                  label-for="remark"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="remark"
                    size="sm"
                    v-model="warehouse.remark"
                />
              </b-form-group>
            </b-col>

          </b-card>
        </b-col>

        <!--计费规则card-->
        <b-col md="4">
          <b-card header="计费规则">
            <b-col md="12">
              <b-input-group
                  label-cols="3"
                  label-cols-lg="3"
                  prepend="计费价格"
                  size="sm"
                  class="mb-1"
              >
                <b-form-input
                    size="sm"
                    class="d-inline-block"
                    v-model="warehouseRent.rent_price"
                />
                <b-input-group-append>
                  <v-select
                      style="width: 100px"
                      :options="costArray"
                      :clearable="true"
                      v-model="costModel"
                      class="select-size-sm"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-col>

            <b-col md="12">
              <b-input-group
                  label-cols="3"
                  label-cols-lg="3"
                  prepend="计费单位"
                  size="sm"
                  class="mb-1"
                  label-for="unit_rule"
              >
                <b-form-input
                    size="sm"
                    class="d-inline-block"
                    v-model="warehouseRent.unit_amount"
                />
                <b-input-group-append>
                  <v-select
                      style="width: 100px"
                      :options="getCodeOptions('warehouse_rent_unit')"
                      :clearable="true"
                      class="select-size-sm"
                      v-model="unit_rule"
                      @input="changeWarehouseRentSelect('unit_rule',$event)"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-col>

            <b-col md="12">
              <b-input-group
                  label-cols="3"
                  label-cols-lg="3"
                  prepend="计费周期"
                  size="sm"
                  class="mb-1"
              >
                <b-form-input
                    size="sm"
                    class="d-inline-block"
                    v-model="warehouseRent.period_amount"
                />
                <b-input-group-append>
                  <v-select
                      style="width: 100px"
                      :options="getCodeOptions('warehouse_rent_period')"
                      :clearable="true"
                      class="select-size-sm"
                      v-model="period_rule"
                      @input="changeWarehouseRentSelect('period_rule',$event)"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-card>
        </b-col>

        <b-modal
            id="modal-select-user"
            ok-only
            ok-title="确认"
            @ok="onSelectUser"
            cancel-title="取消"
            centered
            size="lg"
            title="选择姓名"
        >
          <user-select
              ref="myUserSelect">
          </user-select>


        </b-modal>

        <b-modal
            id="modal-select-company"
            ok-only
            ok-title="确认"
            @ok="onSelectCompany"
            cancel-title="取消"
            centered
            size="lg"
            title="选择仓库方"
        >
          <company-select
              ref="myCompanySelect" :companyType="WareHouseName">
          </company-select>


        </b-modal>

        <!-- 店铺弹出窗口 -->
        <b-modal
            id="modal-select-stores"
            ok-only
            ok-title="确认"
            @ok="onSelectSingle"
            cancel-title="取消"
            centered
            size="xl"
            title="选择店铺"
        >
          <store-warehouse-list
                  ref="storeWarehouseList"
          >
          </store-warehouse-list>
        </b-modal>

        <b-col md="12">
          <div class="inner-card-title">附件</div>
        </b-col>
        <b-col md="12">

          <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="附件"
                  label-for="attachments"
                  label-size="sm"
                  class="mb-1 required"
          >
            <attachment-upload  v-if="warehouse.loaded" :theme="'files'"
                               :attachment_id="'attachments'"
                               :id="warehouse.attachments"
                               :object_type="'warehouse'"
                               :object_id="warehouse.id"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>


        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="save(1)"
          >
            保存
          </b-button>
          <b-button
              variant="outline-secondary"
              @click="cancel"
              class="mr-1"
          >
            返回
          </b-button>

          <b-button
                  v-if="warehouse.status===2||warehouse.warehouse_id===undefined||warehouse.status===5"
                  variant="outline-secondary"
                  @click="save(2)"
          >
            提交
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,BPagination,BInputGroup,BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onMounted, onUnmounted, reactive, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import warehouseStore from './warehouseStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, isEmpty} from '@core/utils/filter'
import CompanySelect from "@/views/apps/company/CompanySelect";

import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import AreaSelect from "@/views/apps/area/AreaSelect";
import areaStore from "@/views/apps/area/areaStore";
import companyStore from "@/views/apps/company/companyStore";

import StoreList from '@/views/apps/store/StoreList'
import storeUseList from '@/views/apps/store/storeUseList'
import storeStore from '@/views/apps/store/storeStore'
import XyInputButton from "@/views/components/xy/XyInputButton";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import StoreWarehouseList from "@/views/apps/store/store-warehouse/StoreList";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    AreaSelect,
    CompanySelect,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    useToast,
    UserSelect,
    StoreList,
    BPagination,
    XyInputButton,
    AttachmentUpload,
    StoreWarehouseList

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: ref(0),
      warehouse: {},
      typeArray:[{"label":"我方","type":1},{"label":"对方","type":2}],
      type:{},
      companyName:{},
      companyId:0,
      companyNames:"",
      contact:"",
      company_name:'',

      province_id: '',

      city_id: '',
      area_id: '',
      level: 3,
      storeArr: [{
        id: null,
        name: ''
      }],
      path: null,
      selected: [],
      printPageType:{},
      WareHouseName:"WareHouse",
      erp_code:undefined,
      warehouseNameYang:undefined,
      costArray:[{'label':'元','value':1}],
      costModel:'元',
      warehouseRent:{},
      unit_rule:'',
      period_rule:'',
      zt_warehouse_type:'',
    }
  },
  methods: {
  },
  setup() {

    const toast = useToast()

    // Register module

    if (!store.hasModule(' ')) store.registerModule('store', storeStore)
    if (!store.hasModule('warehouse')) store.registerModule('warehouse', warehouseStore)
    if (!store.hasModule('area')) store.registerModule('area', areaStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('company')) store.unregisterModule('company')
      if (store.hasModule('warehouse')) store.unregisterModule('warehouse')
      if (store.hasModule('area')) store.unregisterModule('area')
    })


    const edit = function() {
      store.dispatch('warehouse/edit', {id: this.id}).then(res => {
        this.warehouse = res.data.data
        this.warehouseRent = this.warehouse.ext.warehouseRent
        this.zt_warehouse_type = this.warehouse.ext.ztWarehouseType
        this.unit_rule = getCodeLabel('warehouse_rent_unit',this.warehouseRent.unit_rule)
        this.period_rule = getCodeLabel('warehouse_rent_period',this.warehouseRent.period_rule)
        this.erpCode = this.warehouse.erp_code
        this.warehouseNameYang = this.warehouse.warehouse_name
        console.log(this.warehouse)
        // 判断是新增还是编辑

        if (this.id != 0){
          // 店铺回显 this.storeArr
          this.storeArr = this.warehouse.store_ids.length != 0? JSON.parse(this.warehouse.store_ids):this.storeArr
          if (this.storeArr.length == 1 ) {
            for(let i = 0; i < this.storeArr.length; i++) {
              if (this.storeArr[i].id != null) {
                this.storeArr.push({
                  id: null,
                  name: '',
                })
              }
            }
          } else {
            this.storeArr.push({
              id: null,
              name: '',
            })
          }
          this.province_id = this.warehouse.province_id
          this.city_id = this.warehouse.city_id
          this.area_id = this.warehouse.area_id
        }
        this.companyName = this.warehouse.company_name
        this.company_name = this.warehouse.company_name
        this.contact = this.warehouse.contact
        for (let i = 0; i < this.typeArray.length; i++) {
          if (this.warehouse.type === this.typeArray[i].type){
            this.type = this.typeArray[i].label
          }
        }
        this.printPageType = getCode("print_page", this.warehouse.print_pagetype)
        if(this.warehouse.sales_type!=undefined){
          this.warehouse.sales_type = getCodeLabel("store_sales_type",this.warehouse.sales_type)
        }
      })
    }

    const view = function() {
      store.dispatch('warehouse/view', {id: this.id}).then(res => {
        this.warehouse = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function(result) {
      let arr = []
      for (let i = 0; i < this.storeArr.length; i++) {
        if (this.storeArr[i].id == null) {
          continue
        }else{
          arr.push(this.storeArr[i])
        }
      }
      // 绑定仓库
      this.warehouse.store_ids = JSON.stringify(arr).length == 2 ? "": JSON.stringify(arr)

      // 将HorizontalArea组件中的id传到WarehouseEdit中保存
      this.warehouse.province_id = this.$refs.areaSel.province
      this.warehouse.city_id = this.$refs.areaSel.city
      this.warehouse.area_id = this.$refs.areaSel.area
      if(isEmpty(this.warehouse.province_id)){
        toast.error('请选择省份')
        return;
      }
      if(isEmpty(this.warehouse.city_id)){
        toast.error('请选择城市')
        return;
      }
      if(isEmpty(this.warehouse.area_id)){
        toast.error('请选择地区')
        return;
      }

      // 将HorizontalArea组件中的省市名传到WarehouseEdit中保存
      if (typeof (this.$refs.areaSel.selData.provinceId) == "string"){
        this.warehouse.province = this.$refs.areaSel.selData.provinceId
      }else {
        this.warehouse.province = this.$refs.areaSel.selData.provinceId.label
      }

      if (typeof (this.$refs.areaSel.selData.cityId) == "string"){
        this.warehouse.city = this.$refs.areaSel.selData.cityId
      }else {
        this.warehouse.city = this.$refs.areaSel.selData.cityId.label
      }

      if (typeof (this.$refs.areaSel.selData.areaId) == "string"){
        this.warehouse.area = this.$refs.areaSel.selData.areaId
      }else {
        this.warehouse.area = this.$refs.areaSel.selData.areaId.label
      }

      if(this.warehouse.company_id===undefined||this.warehouse.company_id===null||this.warehouse.company_id<0){
        toast.error("仓库方选择错误,请重新选择！")
        return
      }
      if(this.warehouse.attachments===undefined||this.warehouse.attachments===""||this.warehouse.attachments<0){
        toast.error("附件上传错误,请重新选择")
        return
      }
      if (isEmpty(this.warehouseRent.unit_rule)||isEmpty(this.warehouseRent.period_rule)){
        toast.error("计费单位或者计费周期不能位空！")
        return
      }
      const cRegExp = /^[1-9]\d*$/
      if (!isEmpty(this.warehouseRent.rent_price)&&this.warehouseRent.rent_price<=0){
        toast.error("计费价格必须大于0！")
        return
      }
      if (!isEmpty(this.warehouseRent.unit_amount)&&!cRegExp.test(this.warehouseRent.unit_amount)){
        toast.error("计费单位必须是正整数！")
        return
      }
      if (!isEmpty(this.warehouseRent.period_amount)&&!cRegExp.test(this.warehouseRent.period_amount)){
        toast.error("计费周期必须是正整数！")
        return
      }
      this.warehouse.warehouseRent = JSON.stringify(this.warehouseRent)
      if(this.warehouse.erp_code!==this.erpCode||this.warehouse.warehouse_name!==this.warehouseNameYang){
        if(confirm("检测到名称、ERP编码变更，提交后需要财务审核？")) {
          store.dispatch('warehouse/save', this.warehouse).then(res => {
            if (res.data.code === 0) {
              this.warehouse = res.data.data
              if(result===2){
                let status = 3
                let state = 0
                store.dispatch('warehouse/submit', {id: this.warehouse.warehouse_id,state:state, status: status }).then(res => {
                  if(res.data.code===0){
                    toast.success("操作成功")
                  }
                })
              }
              this.$router.push({ name: 'apps-warehouse-listAll',query:{id:this.companyId}})
            } else {
              toast.error(res.data.data)
            }
          })
        }
      }else{
        store.dispatch('warehouse/save', this.warehouse).then(res => {
          if (res.data.code === 0) {
            this.warehouse = res.data.data
            if(result===2){
              let status = 3
              let state = 0
              store.dispatch('warehouse/submit', {id: this.warehouse.warehouse_id,state:state, status: status }).then(res => {
                if(res.data.code===0){
                  toast.success("操作成功")
                }
              })
            }
            this.$router.push({ name: 'apps-warehouse-listAll',query:{id:this.companyId}})
          } else {
            toast.error(res.data.data)
          }
        })
      }



    }


    const onSelectUser = function (bvModalEvt) {
      let checked = this.$refs.myUserSelect.getSelected()[0];
      this.warehouse.contact = checked.full_name
      this.contact = checked.full_name
      this.warehouse.mobile = checked.mobile
      this.warehouse.user_id = checked.user_id

    }



    const removeStore = function(index) {
      let a = this.storeArr[index]
      if (a.id != null) {
        let newArr = []
        for (let i = 0; i < this.storeArr.length; i++) {
          if (i === index) {
            continue
          }
          newArr.push(this.storeArr[i])
        }
        this.storeArr = newArr
      }
    }

    const changeSelect = function (key, event) {
      this.warehouse[key] = event==null?null:event.value
    }

    const changeWarehouseRentSelect = function (key, event) {
      this.warehouseRent[key] = event==null?null:event.value
    }

    const onSelectCompany = function (bvModalEvt) {
      let checked = this.$refs.myCompanySelect.getSelected()[0];
      this.warehouse.company_name = checked.company_name
      this.company_name = checked.company_name
      this.warehouse.company_id = checked.company_id
      this.zt_warehouse_type = getCodeLabel('zt_warehouse_type',checked.zt_warehouse_type)
      this.$forceUpdate()
    }

    const onUploaded = function (id, attachment, result) {
      this.warehouse.attachments = result
    }

    const onSelectSingle = function (params, modal) {
     let data = this.$refs.storeWarehouseList.getSelected()
     let rowVal = data[0]
      let judge = true
      // 判断是否有重复的数据
      for (let i = 0; i < this.storeArr.length; i++) {
        if (this.storeArr[i].id === rowVal.store_id) {
          judge = false
        }
      }
      if (judge) {
        this.storeArr[this.storeArr.length-1].name = rowVal.store_name
        this.storeArr[this.storeArr.length-1].id = rowVal.store_id
        this.storeArr.push({
          id: null,
          name: '',
        })
      }
     /* for (let i = 0; i < modal.length; i++) {
        state.condition[modal[i]] =params==null?null:params[modal[i]]
      }*/
      this.$forceUpdate()
    }


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = storeUseList()

    return {
      edit,
      view,
      cancel,
      save,
      // storeUseList
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      removeStore,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      onSelectUser,
      toTime,
      changeSelect,
      onSelectCompany,
      onUploaded,
      onSelectSingle,
      changeWarehouseRentSelect,

    }
  },
  created() {
    this.path = process.env.VUE_APP_OSS_URL

    this.id = this.$route.query.id || 0;
    this.companyId = this.$route.query.companyId || 0;
    this.companyNames = this.$route.query.companyName || "";
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
